import React from "react"

const ProjectItemBanner = ({ bgColor, title, children }) => {
  return (
    <div className={`bg-${bgColor} py-40`}>
      <div className="container text-center md:text-left">
        <div className="grid lg:grid-cols-2  lg:px-16">
          <h3 className="text-mbr_orange mb-12">{title}</h3>
          <div className="w-full px-4">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default ProjectItemBanner
