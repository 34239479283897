import { graphql } from "gatsby"
import React from "react"
import SiteMetadata from "../components/SiteMetadata"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import GreenWave from "../components/Shapes/GreenWave"

import Carousel from "../components/Carousel"
import Layout from "../layouts/Layout"
import ButtonTag from "../components/UI/ButtonTag"
import ProjectItemBanner from "../components/Banner/ProjectItemBanner"

const ProjectItems = ({ data, location }) => {
  const { name, mission, whatWeDid, tag, description } = data.project
  const image = getImage(data.backgroundImage.edges[0].node)
  const bannerImage = getImage(data.project.bannerImage)
  // const bgImage = convertToBgImage(image)

  const Bold = ({ children }) => (
    <span className="font-extrabold">{children}</span>
  )
  const Italic = ({ children }) => <span className="italic">{children}</span>
  const Underline = ({ children }) => (
    <span className="underline">{children}</span>
  )

  const options_plainText = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="small">{children}</p>
      ),
      [BLOCKS.EMBEDDED_ASSET]: node => (
        <img
          className="mx-auto my-8 md:pr-40"
          src={`https:${node.data.target.fields.file["en-US"].url}`}
          alt="article"
        />
      ),
    },
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
      [MARKS.UNDERLINE]: text => <Underline>{text}</Underline>,
    },
  }

  return (
    <Layout path={location.pathname}>
      <SiteMetadata title="Made By Rise Project" description={description} />
      <div className="bg-mbr_pale">
        <div className="w-full relative">
          <div className="container pb-32 pt-32">
            {tag && tag.map(item => <ButtonTag content={item} />)}
            <h2 className="text-mbr_blue mb-4">{name}</h2>
            <p className="small text-mbr_blue mb-16 max-w-3/4">{description}</p>
            <GatsbyImage image={bannerImage} className="mb-16" />
            <GreenWave />
          </div>
        </div>

        {/* <BgImage
          // Spread bgImage into BackgroundImage:
          image={image}
          style={{ maxWidth: "100vw" }}
          className="flex items-center my-8"
        ></BgImage> */}

        <ProjectItemBanner title="What we did" bgColor="mbr_green">
          {whatWeDid &&
            whatWeDid.map((item, index) => {
              return (
                <h4
                  className="small text-mbr_blue mb-12 last:mb-0 "
                  key={`product-${index}`}
                >
                  {item}
                </h4>
              )
            })}
        </ProjectItemBanner>
        <ProjectItemBanner title="The Brief" bgColor="mbr_pale">
          <p className="small text-mbr_blue text-justify md:text-left">
            {mission && renderRichText(mission, options_plainText)}
          </p>
        </ProjectItemBanner>
        {
          data.project.screenshots ? <div className="container py-12"><Carousel images={data.project.screenshots} /></div> : null
        }

      </div>
    </Layout>
  )
}

export default ProjectItems

export const query = graphql`
  query ProjectBySlug($slug: String) {
    project: contentfulProjects(slug: { eq: $slug }) {
      name
      bannerImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
      whatWeDid
      mission {
        raw
      }
      screenshots {
        gatsbyImageData
      }
      tag
      description
    }
    backgroundImage: allContentfulAsset(
      filter: { title: { eq: "background red wave" } }
    ) {
      edges {
        node {
          gatsbyImageData(width: 2000, quality: 50)
        }
      }
    }
  }
`
